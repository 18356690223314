/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./node_modules/foundation-sites/dist/css/foundation-flex.css"
import "./node_modules/slick-carousel/slick/slick-theme.css"
import "./node_modules/slick-carousel/slick/slick.css"
import "./node_modules/react-image-gallery/styles/css/image-gallery.css"
import "./src/styles/global.css"
