// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-10-trendov-dizaina-js": () => import("./../src/pages/10-trendov-dizaina.js" /* webpackChunkName: "component---src-pages-10-trendov-dizaina-js" */),
  "component---src-pages-dizain-vannaya-komnata-js": () => import("./../src/pages/dizain-vannaya-komnata.js" /* webpackChunkName: "component---src-pages-dizain-vannaya-komnata-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laminat-js": () => import("./../src/pages/laminat.js" /* webpackChunkName: "component---src-pages-laminat-js" */),
  "component---src-pages-plan-remonta-js": () => import("./../src/pages/plan-remonta.js" /* webpackChunkName: "component---src-pages-plan-remonta-js" */),
  "component---src-pages-potolki-js": () => import("./../src/pages/potolki.js" /* webpackChunkName: "component---src-pages-potolki-js" */),
  "component---src-pages-shpaklevka-js": () => import("./../src/pages/shpaklevka.js" /* webpackChunkName: "component---src-pages-shpaklevka-js" */)
}

